import { useEffect, useState } from "react";
import axios from "axios";
import TopNavbar from "../WebParts/TopNavbar";
import Navbar from "../WebParts/Navbar";
import CustomNavbar from "../WebParts/CustomNavbar";
import { Link } from "react-router-dom";

export default function AllBrandsPage({ cart, idSession }) {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchBrands = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_IP}/api/brands`
        );
        setBrands(response.data);
        if (response.data.length === 0) {
          setError(`Nessun brand trovato`);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
        setError("Errore nel recuperare i brands.");
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, []);

  // Funzione per gestire l'input della ricerca
  const handleSearchChange = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  // Filtra i brand in base al testo di ricerca
  const filteredBrands = brands.filter((brand) =>
    brand.brand_name.toLowerCase().includes(search)
  );

  return (
    <>
      <TopNavbar />
      <div className="md:hidden">
        <CustomNavbar cart={cart} idSession={idSession} />
      </div>
      <div className="hidden md:block mb-8">
        <Navbar cart={cart} idSession={idSession} />
      </div>

      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-semibold mb-4">
          Sitemap dei brands
        </h2>

        <input
          type="text"
          placeholder="Cerca brand"
          onChange={handleSearchChange}
          className="mb-4 p-2 border border-gray-300 rounded"
        />

        {loading ? (
          <p>Caricamento dei modelli...</p>
        ) : error ? (
          <p className="text-red-600">{error}</p>
        ) : (
          <ul>
            {filteredBrands.map((brand, index) => (
              <li key={index}>
                  <Link to={`/brand/${brand.brand_name}`} className='hover:text-blue-500 hover:underline underline-offset-2'>{brand.brand_name}</Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}
