import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/Pages/Homepage/Homepage';
import ProductPage from './components/Pages/PaginaProdotto/ProductPage';
import CheckoutCart from './components/Pages/Cart/CheckoutCart';
import CostumerDetails from './components/Pages/Checkout/CostumerDetails';
import ProductsSearch from './components/Pages/RisultatiRicerca/ProductsSearch';
import ClientService from './components/Pages/ServizioClienti/ClientService';
import ProductRequest from './components/Pages/ServizioClienti/ProductRequest';
import OrderRequest from './components/Pages/ServizioClienti/OrderRequest';
import OtherQuestion from './components/Pages/ServizioClienti/OtherQuestion';
import AdminLogin from './components/Pages/Admin/AdminComponent/AdminLogin';
import AdminHome from './components/Pages/Admin/AdminHome';
import OrderCompleted from './components/Pages/OrdineCompletato/OrderCompleted';
import OrderCompletedStripe from './components/Pages/OrdineCompletato/OrderCompletedStripe';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import CommonFunction from './components/Pages/Admin/AdminComponent/CommonFunction';
import RisultatiRicerca from './components/Pages/RisultatiRicerca/RisultatiRicerca';
import BrandPage from './components/Pages/Brand/BrandPage';
import AllBrandsPage from './components/Pages/Brand/AllBrandsPage';
import SearchResults from './components/Pages/Brand/SearchResults';
import RisultatiRicercaBrand from './components/Pages/Brand/RisultatiRicercaBrand';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function App() {

  const [cart, setCart] = useState({})
  const [adminIsAuthenticated, setAdminIsAuthenticated] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0)
  const [shippingPrice, setShippingPrice] = useState(8.90)
  const [numeroOrdine, setNumeroOrdine] = useState("0");
  const [idSession, setIdSession] = useState("0");

  // Searchbar state
  const [apparecchio, setApparecchio] = useState("")
  const [marca, setMarca] = useState("")
  const [productCode, setProductCode] = useState("")
  const [codes, setCodes] = useState([])

  // Funzione che fa si che quando l'utente va su una nuova pagina, allora si ritrova al top della pagina
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
  
  return (
    <PayPalScriptProvider options={{ "client-id": "AdgdpU5Jqp1B4fN2DaNhzAua6bNXaj9Sb4xPgo7RSAj-FDF1EXqqB_qlmp9STspbUMWF4rS6FVjIDKkS", "currency": "EUR" }}>
    <Router>
      <ScrollToTop />
      <div>
        <CommonFunction setIdSession={setIdSession}/>
        <Routes>
          <Route path="/" element={<Homepage cart={ cart } setProductCode={setProductCode} idSession={idSession}/>} />
          <Route path="/search/filters/:productId/:page" element={<ProductsSearch cart={ cart } setCart={ setCart } apparecchio={apparecchio} setApparecchio={setApparecchio} marca={marca} setMarca={setMarca} productCode={productCode} setProductCode={setProductCode} codes={codes} setCodes={setCodes} idSession={idSession}/>} />
          <Route path="/product/:productId" element={<ProductPage cart={ cart } setCart={ setCart } idSession={ idSession }/>} />
          <Route path="/checkout/cart" element={<CheckoutCart cart={ cart } setCart={ setCart } setTotalPrice={ setTotalPrice } totalPrice={totalPrice} shippingPrice={shippingPrice} setShippingPrice={setShippingPrice} idSession={ idSession }/>} />
          <Route path="/checkout/costumer_details" element={<CostumerDetails cart={ cart } setCart={ setCart } setTotalPrice={ setTotalPrice } totalPrice={totalPrice} shippingPrice={shippingPrice} numeroOrdine={numeroOrdine} setNumeroOrdine={setNumeroOrdine} idSession={idSession}/>} />
          <Route path="/clientservice" element={<ClientService cart={ cart } setCart={ setCart } idSession={idSession}/>} />
          <Route path="/productrequest" element={<ProductRequest cart={ cart } setCart={ setCart } idSession={idSession}/>} />
          <Route path="/orderrequest" element={<OrderRequest cart={ cart } setCart={ setCart } idSession={idSession}/>} />
          <Route path="/otherquestion" element={<OtherQuestion cart={ cart } setCart={ setCart } idSession={idSession}/>} />
          <Route path="/adminlogin" element={<AdminLogin adminIsAuthenticated={adminIsAuthenticated} setAdminIsAuthenticated={setAdminIsAuthenticated}/>} />
          <Route path="/admin" element={<AdminHome adminIsAuthenticated={adminIsAuthenticated} setAdminIsAuthenticated={setAdminIsAuthenticated}/>} />
          <Route path="/ordercompleted" element={<OrderCompleted cart={ cart } setCart={ setCart } totalPrice={totalPrice} shippingPrice={shippingPrice} idSession={idSession} setIdSession={setIdSession}/>} />
          <Route path="/ordercompletedstripe" element={<OrderCompletedStripe cart={ cart } setCart={ setCart } shippingPrice={shippingPrice} idSession={idSession} setIdSession={setIdSession}/>} />
          <Route path='/results/:keywords' element={<RisultatiRicerca idSession={idSession} cart={cart}/>} />
          <Route path='/brand/:brand' element={<BrandPage idSession={ idSession } cart={ cart }/>}/>
          <Route path='/brands' element={<AllBrandsPage idSession={ idSession } cart={ cart }/>}/>
          <Route path="/productSearch/:productId/:brand" element={<SearchResults cart={ cart } setCart={ setCart } idSession={ idSession }/>} />
          <Route path='/resultsBrand/:keywords/:brand' element={<RisultatiRicercaBrand idSession={idSession} cart={cart}/>} />
        </Routes>
      </div>
    </Router>
    </PayPalScriptProvider>
  )
}

export default App;